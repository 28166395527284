import { NgModule } from '@angular/core';
import {NbCardModule, NbLayoutModule, NbUserModule} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import {SliderContentComponent} from './slider-content/slider-content.component';
import {SliderContentItemComponent} from './slider-content-item/slider-content-item.component';
import {ImageWithLoadingComponent} from './image-with-loading/image-with-loading.component';

@NgModule({
    imports: [
        NbLayoutModule,
        NbCardModule,
        CommonModule,
        NbUserModule,
    ],
  declarations: [
    SliderContentComponent,
    SliderContentItemComponent,
    ImageWithLoadingComponent,
  ],
  exports: [
    SliderContentComponent,
    SliderContentItemComponent,
    ImageWithLoadingComponent,
  ],
})
export class ComponentsModule {
}
