import {NgModule} from '@angular/core';
import {AppUtilsService} from './app-utils/app-utils.service';

@NgModule({
  imports: [],
  providers: [
    AppUtilsService,
  ],
})
export class ServicesModule {
}
