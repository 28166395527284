import { environment } from '../../environments/environment';
import Keycloak, { KeycloakConfig } from 'keycloak-js';

export function initializer(): () => Promise<void> {
    return (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const config: KeycloakConfig = {
                    clientId: 'plataforma-digital',
                    realm: 'master',
                    url: environment.url_keycloak,
                }
                const keycloak = Keycloak(config);
                await keycloak.init({
                    onLoad: 'login-required'
                });
                resolve();
            } catch (error) {
                reject(error)
            }
        });
    };
}
