import {AfterContentChecked, Component, ContentChildren, QueryList, ViewEncapsulation} from '@angular/core';
import { SliderContentItemComponent } from '../slider-content-item/slider-content-item.component';

@Component({
  selector: 'ngx-app-slider-content',
  templateUrl: './slider-content.component.html',
  styleUrls: ['./slider-content.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderContentComponent implements AfterContentChecked {

  constructor() {
  }

  @ContentChildren(SliderContentItemComponent)
  _items: QueryList<SliderContentItemComponent>;
  selectedIndex = 0;

  ngAfterContentChecked() {
    this._items.forEach((item: SliderContentItemComponent, index: number) => {
      item.position = index - this.selectedIndex;
    });
  }
}
