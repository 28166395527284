import {Injectable} from '@angular/core';

@Injectable()
export class AppUtilsService {
  constructor() {
  }

  scroll(x: number, y: number) {
    console.info(`scrolling to ${x},${y}`);
    window.scrollTo(x, y);
    console.info(`scrolling to ${x},${y}-ok`);
  }

  scrollTimeout(x: number, y: number) {
    console.info(`scrolling with timeout to ${x},${y}`);
    setTimeout(() => this.scroll(x, y), 1000);
  }

  toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    // @ts-ignore
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen ||
      // @ts-ignore
      docEl.msRequestFullscreen;

    // @ts-ignore
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    // @ts-ignore
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  requestFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    // @ts-ignore
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen ||
      // @ts-ignore
      docEl.msRequestFullscreen;

    // @ts-ignore
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    // @ts-ignore
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
  }

  cancelFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    // @ts-ignore
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen ||
      // @ts-ignore
      docEl.msRequestFullscreen;

    // @ts-ignore
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    // @ts-ignore
    if (doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement) {
      cancelFullScreen.call(doc);
    }
  }

  isSmartphone() {
    const useragent = window.navigator.userAgent.toLowerCase();
    return useragent.includes('iphone') || useragent.includes('android');
  }

  isTablet() {
    const useragent = window.navigator.userAgent.toLowerCase();
    return useragent.includes('ipad');
  }

  isMobile() {
    return this.isSmartphone() || this.isTablet();
  }

  isDesktop() {
    return !this.isMobile();
  }
}
