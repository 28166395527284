import {Component, Input} from '@angular/core';

@Component({
  selector: 'ngx-image-with-loading',
  templateUrl: './image-with-loading.component.html',
  styleUrls: ['./image-with-loading.component.css'],
})
export class ImageWithLoadingComponent {

  @Input() loader: string = 'assets/images/image-loading.gif';
  @Input() height: string;
  @Input() width: string;
  @Input() src: string;
  @Input() alt: string;

  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  hideLoader() {
    this.isLoading = false;
  }
}
